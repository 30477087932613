import { getCart, transferCartItems } from "./cart"
import { navigate } from "gatsby"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
  return isBrowser() && window.localStorage.getItem("localUser")
    ? JSON.parse(window.localStorage.getItem("localUser"))
    : {}
}

const setUser = (user) =>
  isBrowser && window.localStorage.setItem("localUser", JSON.stringify(user))

export const handleLogin = async ({ email, password }) => {
  let cartItems = []
  if (isGuestRole()) {
    const user = getUser()

    cartItems = await getCart().then((res) => {
      if (res.status && res.data.result) {
        return res.data.result
      } else {
        return false
      }
    })
    // console.log("cartItems", cartItems)
  }

  const data = {
    email: email,
    password: password,
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${process.env.GATSBY_PORTAL_TOKEN}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }

  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/login`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    if (response.status === true) {
      const user = response.data

      //user.role = 'guest';
      // console.log("setUser(user)", user)
      setUser(user)

      const transfer = isGuestRole() ? await transferCartItems(cartItems) : ""
      const handleSetGuestRole = await setGuestRole(false)
    }
    // console.log("handleLogin() Response: ", response)
    return response
  } catch (error) {
    return error
  }
}

export const setGuestRole = async (guest = false) => {
  // console.log("Guest: ", guest)
  if (guest) {
    const data = {
      guest: true,
    }
    isBrowser && window.localStorage.setItem("guestUser", JSON.stringify(data))
  } else {
    isBrowser && window.localStorage.removeItem("guestUser")
  }
  return true
}

export const setCanGuestChangePassword = async (
  allowed = false,
  changed = false,
  email = false
) => {
  const data = {
    allowed: allowed,
    changed: changed,
    email: email,
  }
  // console.log("Guest: ", guest)
  isBrowser &&
    window.localStorage.setItem("guestCanChangePassword", JSON.stringify(data))

  return true
}

export const canGuestChangePassword = () => {
  return isBrowser() && window.localStorage.getItem("guestCanChangePassword")
    ? JSON.parse(window.localStorage.getItem("guestCanChangePassword"))
    : false
}

export const isGuestRole = (guest) => {
  return isBrowser() && window.localStorage.getItem("guestUser")
    ? JSON.parse(window.localStorage.getItem("guestUser"))
    : false
}

export const isLoggedIn = async () => {
  return await userAuthedPortal()
}

export const isLoggedInSoft = () => {
  const user = getUser()
  if (user.id && !isGuestRole()) {
    return true
  } else {
    return false
  }
}

export const syncUser = async (syncGuest = false) => {
  //const disableGuest = await setGuestRole(false);
  return await userAuthedPortal(syncGuest)
}

// #TODO
export const logout = (callback) => {
  isBrowser && window.localStorage.removeItem("guestUser")
  isBrowser && window.localStorage.removeItem("localUser")
  isBrowser && window.localStorage.removeItem("guestCanChangePassword")
  navigate(`/`)
}

export const clearUser = (callback) => {
  isBrowser && window.localStorage.removeItem("guestUser")
  isBrowser && window.localStorage.removeItem("localUser")
  isBrowser && window.localStorage.removeItem("guestCanChangePassword")
}

export const userAuthedPortal = async (syncGuest = false) => {
  const user = getUser()
  if (!user.id) {
    return false
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer  ${user.api_token}`,
    },
    redirect: "follow",
  }
  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/user`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    if (response.status === true) {
      if (isGuestRole()) {
        if (syncGuest == false) {
          return false
        }
      }

      setUser(response.data)
      return true
    } else {
      setUser({})
      setGuestRole(false)
      return false
    }
  } catch (error) {
    return error
  }
}
