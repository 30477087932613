import React, { createContext, useContext, useState, useEffect } from "react"
import {
  isLoggedIn,
  syncUser,
  setGuestRole,
  isGuestRole,
  getUser,
  isLoggedInSoft,
  canGuestChangePassword,
  setCanGuestChangePassword
} from "../services/auth"
import { getCartCount } from "../services/cart"

const defaultContext = {
  contextIsUserLoggedIn: false,
  contextCartCount: 0,
  contextBottleCount: 0,
  contextCanGuestChangePassword: false,
  handleContextCheckLoggedIn: () => {},
  handleSyncUser: () => {},
  isGuestUser: false,
  handleContextGetCartCount: () => {},
  contextSetCanGuestChangePassword: () => {},
}

export const AuthContext = createContext(defaultContext)

export const AuthProvider = ({ children }) => {
  const [contextIsUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [contextCartCount, setCartCount] = useState(false)
  const [contextBottleCount, setContextBottleCount] = useState(false)
  const [contextCanGuestChangePassword, setContextCanGuestChangePassword] = useState(false)

  useEffect(() => {
    isLoggedIn().then((res) => {
      // console.log("AuthContext - Checking for user logged in", res)
      setIsUserLoggedIn(res)
    })
  }, [])

  useEffect(() => {
    const loggedInSoft = isLoggedInSoft()
    // console.log("AuthContext - Checking for user logged in SOFT", loggedInSoft)
    setIsUserLoggedIn(loggedInSoft)
  })

  const handleContextCheckLoggedIn = () => {
    isLoggedIn().then((res) => {
      // console.log("AuthContext - Checking for user logged in", res)
      setIsUserLoggedIn(res)
    })
  }

  const handleSyncUser = () => {
    syncUser().then((res) => {
      // console.log("Syncing User", res)
      setIsUserLoggedIn(res)
    })
  }

  const contextSetCanGuestChangePassword = (allowed = false, changed = false, email = false, ) => {
   
    setCanGuestChangePassword(allowed, changed, email)
    return contextHandleCanGuestChangePassword()

  }  

  const contextHandleCanGuestChangePassword = () => {
    const data = canGuestChangePassword()
    setContextCanGuestChangePassword(data)
  }

  const isGuestUser = () => {
    return isGuestRole()
  }

  const handleContextGetCartCount = async () => {
    const user = getUser()
    if (!user.id) {
      setCartCount("0")
      setContextBottleCount("0")
    }
    await getCartCount().then((res) => {
      if (res.status && res.data.cart_count) {
        setCartCount(res.data.cart_count)
        setContextBottleCount(res.data.cart_quanitiy)
      } else {
        setCartCount("0")
        setContextBottleCount("0")
      }
    })
  }

  return (
    <AuthContext.Provider
      value={{
        contextIsUserLoggedIn: contextIsUserLoggedIn,
        contextCartCount: contextCartCount,
        contextBottleCount: contextBottleCount,
        contextCanGuestChangePassword: contextCanGuestChangePassword,
        handleContextCheckLoggedIn,
        handleSyncUser,
        isGuestUser,
        handleContextGetCartCount,
        contextHandleCanGuestChangePassword,
        contextSetCanGuestChangePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

