exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-auth-js": () => import("./../../../src/pages/checkout-auth.js" /* webpackChunkName: "component---src-pages-checkout-auth-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-forgot-password-request-js": () => import("./../../../src/pages/forgot-password-request.js" /* webpackChunkName: "component---src-pages-forgot-password-request-js" */),
  "component---src-pages-forgot-password-reset-js": () => import("./../../../src/pages/forgot-password-reset.js" /* webpackChunkName: "component---src-pages-forgot-password-reset-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-regions-js": () => import("./../../../src/pages/regions.js" /* webpackChunkName: "component---src-pages-regions-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */),
  "component---src-pages-wineries-js": () => import("./../../../src/pages/wineries.js" /* webpackChunkName: "component---src-pages-wineries-js" */),
  "component---src-templates-cellar-js": () => import("./../../../src/templates/cellar.js" /* webpackChunkName: "component---src-templates-cellar-js" */),
  "component---src-templates-collection-parent-js": () => import("./../../../src/templates/collection-parent.js" /* webpackChunkName: "component---src-templates-collection-parent-js" */),
  "component---src-templates-custom-url-js": () => import("./../../../src/templates/custom-url.js" /* webpackChunkName: "component---src-templates-custom-url-js" */),
  "component---src-templates-grape-child-js": () => import("./../../../src/templates/grape-child.js" /* webpackChunkName: "component---src-templates-grape-child-js" */),
  "component---src-templates-inner-page-js": () => import("./../../../src/templates/inner-page.js" /* webpackChunkName: "component---src-templates-inner-page-js" */),
  "component---src-templates-news-detail-js": () => import("./../../../src/templates/news-detail.js" /* webpackChunkName: "component---src-templates-news-detail-js" */),
  "component---src-templates-pairing-child-js": () => import("./../../../src/templates/pairing-child.js" /* webpackChunkName: "component---src-templates-pairing-child-js" */),
  "component---src-templates-price-range-child-js": () => import("./../../../src/templates/price-range-child.js" /* webpackChunkName: "component---src-templates-price-range-child-js" */),
  "component---src-templates-region-child-js": () => import("./../../../src/templates/region-child.js" /* webpackChunkName: "component---src-templates-region-child-js" */),
  "component---src-templates-region-parent-js": () => import("./../../../src/templates/region-parent.js" /* webpackChunkName: "component---src-templates-region-parent-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-wine-child-js": () => import("./../../../src/templates/wine-child.js" /* webpackChunkName: "component---src-templates-wine-child-js" */),
  "component---src-templates-wine-detail-js": () => import("./../../../src/templates/wine-detail.js" /* webpackChunkName: "component---src-templates-wine-detail-js" */),
  "component---src-templates-winery-detail-js": () => import("./../../../src/templates/winery-detail.js" /* webpackChunkName: "component---src-templates-winery-detail-js" */)
}

