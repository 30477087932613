import {
  isLoggedIn,
  getUser,
  handleLogin,
  setGuestRole,
  isGuestRole,
} from "./auth"
import { registerUser } from "./register"

export const getCart = async () => {
  const user = getUser()
  if (!user.id) {
    return {}
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer  ${user.api_token}`,
    },
    redirect: "follow",
  }

  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/my-cart`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    // console.log("GET: api/cart", response)
    return response
  } catch (error) {
    return error
  }
}

export const getCartCount = async () => {
  const user = getUser()
  if (!user.id) {
    return {}
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer  ${user.api_token}`,
    },
    redirect: "follow",
  }

  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/my-cart-count`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    // console.log("GET: api/cart", response)
    return response
  } catch (error) {
    return error
  }
}

export const addToCart = async (data) => {
  const contextIsUserLoggedIn = await isLoggedIn()
  data.user =
    contextIsUserLoggedIn || isGuestRole() ? getUser() : await getTempUser()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }
  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/add-to-cart`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    return response
  } catch (error) {
    return error
  }
}

export const removeCartItem = async (data) => {
  //TODO - Check auth first. Can't get cart if not logged in.

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }
  // console.log("boomfa", requestOptions)
  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/remove-cart-item`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    // console.log("POST: api/cart/remove-cart-item", response)
    return response
  } catch (error) {
    return error
  }
}

export const updateCartItemQuantity = async (data) => {
  //TODO - Check auth first. Can't get cart if not logged in.
  const contextIsUserLoggedIn = await isLoggedIn()
  data.user =
    contextIsUserLoggedIn || isGuestRole() ? getUser() : await getTempUser()

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }
  // console.log("boomfa", requestOptions)
  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/update-cart-item-quantity`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    // console.log("POST: api/cart/update-cart-item-quantity", response)
    return response
  } catch (error) {
    return error
  }
}

export const getTempUserPassword = (email) => {
  return "Ab" + hashCode(email).toString().substring(0, 9) + "1"
}

export const getTempUser = async () => {
  let email = genRand(10) + "@notifications.iheartwine.com.au"
  let password = getTempUserPassword(email)
  const data = {
    email: email,
    password,
    confirm_password: password,
  }
  try {
    const registerResponse = await registerUser(data)
    // console.log("registerResponse", registerResponse)

    const loginResponse = await handleLogin({ email, password })
    const handleSetGuestRole = await setGuestRole(true)
    // console.log("loginResponse", loginResponse)
    return loginResponse.status ? loginResponse.data : {}
  } catch (error) {
    return error
  }
}

const genRand = (len) => {
  return (
    Math.random()
      .toString(36)
      .substring(2, len + 2) + new Date().getTime()
  )
}

export const hashCode = (str) => {
  return str
    .split("")
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    )
}

export const transferCartItems = async (cartItems) => {
  const user = getUser()
  await cartItems.map((winery) =>
    winery.item.map(async (product) => {
      // console.log("productItem Winery", winery)
      // console.log("productItem ITEM", product)
      const addToCartData = {
        user: user,
        id: product.wine_id, //required
        type: "wine", //required wine/product
        quantity: product.selected_quantity, //required
      }
      const toCart = await addToCart(addToCartData).then((res) => {
        // console.log("addToCart Response: ", res)
      })
    })
  )
}
