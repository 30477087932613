import { resolveConfig } from "prettier"

export const registerUser = async (data) => {

    const requestOptions = {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "version-code": "1",
            "device-type": "iOS",
            authorization: `Bearer ${process.env.GATSBY_PORTAL_TOKEN}`,
        },
        body: JSON.stringify(data),
        redirect: "follow",
    }

    try {
        const response = await fetch(
            `${process.env.GATSBY_PORTAL_URL}/signup`,
            requestOptions
        ).then((res) => {
            return res.json()
        })

        return response

    } catch (error) {
        return error
    }   


 
    
}          

